import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import Button from './button';
import useLanguageStrings from '../hooks/useLanguageStrings';

export default function Controls({
  buttonHandler,
  nextPath,
  positiveAnswer,
  neutralAnswer,
}) {
  const { skip } = useLanguageStrings();
  return (
    <nav className="nav">
      <div className="controls">
        <Button nextPath={nextPath} pressHandler={buttonHandler} value={0} txt={neutralAnswer || 'No'} />
        <Button nextPath={nextPath} pressHandler={buttonHandler} value={1} txt={positiveAnswer || 'Yes'} />
      </div>
      <div className="skip">
        <Link to={nextPath}>
          {skip}
        </Link>
      </div>
    </nav>
  );
}

Controls.propTypes = {
  buttonHandler: PropTypes.func.isRequired,
  nextPath: PropTypes.string.isRequired,
  positiveAnswer: PropTypes.string.isRequired,
  neutralAnswer: PropTypes.string.isRequired,
};
