import React from 'react';
import PropTypes from 'prop-types';
import '../style/progress.scss';
import Circle from '../images/circle.svg';
import CircleFilled from '../images/circle-filled.svg';

function Progress({ index, questionCount }) {
  return (
    <div className="progress" role="progressbar" aria-valuenow={index + 1} aria-valuemin="1" aria-valuemax={questionCount}>
      {[...Array(questionCount)].map((n, i) => {
        const C = i <= index ? CircleFilled : Circle;
        const classes = i <= index ? 'fill' : '';
        return <C key={i.toString()} alt={`Progress: ${i + 1}`} className={classes} />;
      })}
    </div>
  );
}

Progress.propTypes = {
  index: PropTypes.number.isRequired,
  questionCount: PropTypes.number.isRequired,
};

export default Progress;
