import React from 'react';
import PropTypes from 'prop-types';
import TransitionLink from 'gatsby-plugin-transition-link';

export default function Button({
  nextPath,
  pressHandler,
  value,
  txt,
}) {
  function clickHandler() {
    pressHandler(value);
  }
  return (
    <TransitionLink
      to={nextPath}
      role="button"
      className="button"
      onClick={clickHandler}
      // exit={{
      //   length: 0.325,
      // }}
      entry={{
        length: 0.650,
      }}
    >
      {txt}
    </TransitionLink>
  );
}

Button.propTypes = {
  nextPath: PropTypes.string.isRequired,
  pressHandler: PropTypes.func.isRequired,
  txt: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
};
