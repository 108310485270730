import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import '../style/question.scss';
import Rectangle from '../components/rectangle';
import Footer from '../components/footer';
import touchEnabled from '../lib/touch-enabled';
import Title from '../components/title';

export default function Layout({ children, id, theme, currentPath, round }) {
  const bodyAttributes = {
    class: theme + (touchEnabled() ? ' touch-enabled' : ' touch-disabled'),
  };
  return (
    <>
      <Helmet bodyAttributes={bodyAttributes} />
      <div id={id} className="container">
        <header>
          <Title />
          <div className="upper-east">
            <Rectangle round={round} className="animation-off" />
          </div>
        </header>
        <main>{children}</main>
        <Footer currentPath={currentPath} />
      </div>
    </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
  currentPath: PropTypes.string.isRequired,
  round: PropTypes.number.isRequired,
};
