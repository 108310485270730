import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Controls from '../components/controls';
import { onAnswer } from '../lib/brain';
import Layout from '../layouts/question';
import SEO from '../components/seo';
import Progress from '../components/progress';

export const query = graphql`
  query GetQuestion($id: String!, $locale: String!) {
    contentfulQuestion(
      contentful_id: { eq: $id }
      node_locale: { eq: $locale }
    ) {
      question {
        id
        question
      }
      discipline {
        id
        name
      }
      positiveAnswer
      neutralAnswer
    }
  }
`;

function Question({ pageContext, data, transitionStatus }) {
  const { currentPath, nextPath, index, round, theme, questionCount } =
    pageContext;
  const { id, question } = data.contentfulQuestion.question;
  const { discipline, positiveAnswer, neutralAnswer } = data.contentfulQuestion;
  const { id: disciplineId, name: disciplineName } = discipline;
  const q = {
    id,
    disciplineId,
    disciplineName,
    question,
  };
  function buttonHandler(value) {
    onAnswer(q, value);
  }
  return (
    <>
      <SEO title={question} />
      <Layout
        id={`q_${id}`}
        theme={theme}
        currentPath={currentPath}
        round={round}
      >
        <fieldset>
          <legend>
            <div className={transitionStatus}>{question}</div>
          </legend>
          <Controls
            buttonHandler={buttonHandler}
            nextPath={nextPath}
            positiveAnswer={positiveAnswer}
            neutralAnswer={neutralAnswer}
          />
        </fieldset>
      </Layout>
      <Progress index={index} questionCount={questionCount} />
    </>
  );
}

Question.defaultProps = {
  transitionStatus: '',
};

Question.propTypes = {
  data: PropTypes.shape({
    contentfulQuestion: PropTypes.shape({
      question: PropTypes.shape({
        id: PropTypes.string.isRequired,
        question: PropTypes.string.isRequired,
      }).isRequired,
      discipline: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }).isRequired,
      positiveAnswer: PropTypes.string.isRequired,
      neutralAnswer: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    id: PropTypes.string.isRequired,
    currentPath: PropTypes.string.isRequired,
    nextPath: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    round: PropTypes.number.isRequired,
    theme: PropTypes.string.isRequired,
    questionCount: PropTypes.number.isRequired,
  }).isRequired,
  transitionStatus: PropTypes.string,
};

export default Question;
